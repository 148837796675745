/*fonts have to be defined in standalone css instead of styled-components*/
/*otherwise they are often reloaded, causing unnecessary requests and flickering*/
/*https://github.com/styled-components/styled-components/issues/1593*/

@font-face {
    font-family: "Lato";
    src: url(fonts/Lato-Hairline.woff2) format('woff2');
    font-weight: 100;
}

@font-face {
    font-family: "Lato";
    src: url(fonts/Lato-HairlineItalic.woff2) format('woff2');
    font-weight: 100;
    font-style: italic;
}


@font-face {
    font-family: "Lato";
    src: url(fonts/Lato-Thin.woff2) format('woff2');
    font-weight: 200;
}

@font-face {
    font-family: "Lato";
    src: url(fonts/Lato-ThinItalic.woff2) format('woff2');
    font-weight: 200;
    font-style: italic;
}


@font-face {
    font-family: "Lato";
    src: url(fonts/Lato-Light.woff2) format('woff2');
    font-weight: 300;
}

@font-face {
    font-family: "Lato";
    src: url(fonts/Lato-LightItalic.woff2) format('woff2');
    font-weight: 300;
    font-style: italic;
}


@font-face {
    font-family: "Lato";
    src: url(fonts/Lato-Regular.woff2) format('woff2');
    font-weight: normal;
    /*font-weight: 400;*/

}

@font-face {
    font-family: "Lato";
    src: url(fonts/Lato-Italic.woff2) format('woff2');
    font-weight: normal;
    /*font-weight: 400;*/
    font-style: italic;
}


@font-face {
    font-family: "Lato";
    src: url(fonts/Lato-Medium.woff2) format('woff2');
    font-weight: 500;
}

@font-face {
    font-family: "Lato";
    src: url(fonts/Lato-MediumItalic.woff2) format('woff2');
    font-weight: 500;
    font-style: italic;
}


@font-face {
    font-family: "Lato";
    src: url(fonts/Lato-Semibold.woff2) format('woff2');
    font-weight: 600;
}

@font-face {
    font-family: "Lato";
    src: url(fonts/Lato-SemiboldItalic.woff2) format('woff2');
    font-weight: 600;
    font-style: italic;
}


@font-face {
    font-family: "Lato";
    src: url(fonts/Lato-Bold.woff2) format('woff2');
    font-weight: bold;
    /*font-weight: 700;*/
}

@font-face {
    font-family: "Lato";
    src: url(fonts/Lato-BoldItalic.woff2) format('woff2');
    font-weight: bold;
    /*font-weight: 700;*/
    font-style: italic;
}


@font-face {
    font-family: "Lato";
    src: url(fonts/Lato-Heavy.woff2) format('woff2');
    font-weight: 800;
}

@font-face {
    font-family: "Lato";
    src: url(fonts/Lato-HeavyItalic.woff2) format('woff2');
    font-weight: 800;
    font-style: italic;
}


@font-face {
    font-family: "Lato";
    src: url(fonts/Lato-Black.woff2) format('woff2');
    font-weight: 900;
}

@font-face {
    font-family: "Lato";
    src: url(fonts/Lato-BlackItalic.woff2) format('woff2');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: "AvertaPE";
    src: url(./fonts/AvertaPE-Regular.otf) format('opentype');
}

@font-face {
    font-family: "AvertaPE";
    src: url(./fonts/AvertaPE-Bold.otf) format('opentype');
    font-weight: bold;
}

@font-face {
    font-family: "AvertaPE";
    src: url(./fonts/AvertaPE-Extrabold.otf) format('opentype');
    font-weight: 800;
}